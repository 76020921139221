<template>
	<div class="lineManagement">
		<jy-query ref="checkForm" :model="formInline">
			<jy-query-item label="站点编号:" prop="siteNu">
				<el-input v-model="formInline.siteNu" placeholder="请输入"></el-input>
			</jy-query-item>
			<jy-query-item label="站点名称:" prop="cName">
				<el-input v-model="formInline.cName" placeholder="请输入"></el-input>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck" v-if="btnexist('scheduleBasicSiteInfoList')">查询</el-button>
				<el-button type="primary" plain @click="resetForm('checkForm')" v-if="btnexist('scheduleBasicSiteInfoReset')"
					>重置</el-button
				>
				<!-- <el-button type="primary" @click="onexport">导出</el-button> -->
				<!-- <el-button type="primary" @click="add" >新增</el-button> -->
				<!-- <el-row>
                </el-row> -->
			</template>
			<template slot="operate">
				<el-button type="primary" @click="add" v-if="btnexist('scheduleBasicSiteInfoInsert')">新增</el-button>
				<el-button type="primary" @click="batchdelete" v-if="btnexist('scheduleBasicSiteInfoBatchDelete')">批量删除</el-button>
				<!-- <el-button type="primary" @click="batchimport">导出</el-button>
                            <el-button type="primary" @click="batchimport">导入</el-button> -->
			</template>
		</jy-query>
		<jy-table max-height="595" ref="jyTable" :data="lists" @select-all="selectallchange" @select="selectchange">
			<jy-table-column type="index" width="60" label="序号" align="center"> </jy-table-column>
			<jy-table-column type="selection" width="40"></jy-table-column>
			<jy-table-column prop="siteNu" label="站点编号" min-width="100"> </jy-table-column>
			<jy-table-column prop="cName" label="站点名称" min-width="140"> </jy-table-column>
			<jy-table-column prop="bCity" label="所属城市" min-width="120"> </jy-table-column>
			<jy-table-column prop="alias" label="别名" min-width="100"> </jy-table-column>
			<jy-table-column prop="sType" label="站点类别" min-width="100"> </jy-table-column>
			<jy-table-column prop="lng" label="经度" min-width="100"> </jy-table-column>
			<jy-table-column prop="lat" label="纬度" min-width="100"> </jy-table-column>
			<jy-table-column prop="radius" label="站点半径" min-width="100"> </jy-table-column>
			<jy-table-column prop="berths" label="泊位数" min-width="100"> </jy-table-column>
			<jy-table-column prop="isLargePassSup" label="是否大客流监管站点" min-width="140"> </jy-table-column>
			<jy-operate v-bind:list="operateList" width="160"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		>
		</jy-pagination>
		<add-dialog ref="dialog" @updateList="getlist"></add-dialog>
		<audio-management ref="audioManagement" @updateList="getlist"></audio-management>
	</div>
</template>
<script>
import adddialog from "@/components/pages/admin/basicInformation/siteManagement/addsite";
import audioManagement from "@/components/pages/admin/basicInformation/siteManagement/audioManagement";
import { btnMixins } from "@/common/js/button.mixin";
export default {
	data() {
		return {
			formInline: {
				siteNu: "",
				cName: ""
			},
			lists: [],
			// 批量删除
			batchdeleteList: [],
			// 分页
			pageIndex: 1,
			total: 100,
			pageSize: 10,
			//  操作
			operateList: [],

			btnMenuId: "scheduleBasicSiteInfo"
		};
	},
	mixins: [btnMixins],
	components: {
		"add-dialog": adddialog,
		audioManagement
	},
	created() {
		this.getlist();
		this.setOperateList();
	},
	methods: {
		setOperateList() {
			let l = [
				{
					name: "修改",
					icon: "el-icon-edit-outline",
					fun: this.handleEdit,
					isShow: () => {
						return this.btnexist("scheduleBasicSiteInfoUpdate");
					}
				},
				{
					name: "删除",
					icon: "el-icon-delete",
					fun: this.handleDelete,
					isShow: () => {
						return this.btnexist("scheduleBasicSiteInfoDelete");
					}
				}
				// {
				//     name: '报站音频管理',
				//     icon: 'el-icon-delete',
				//     fun: this.handleAutoMag,
				//     isShow:()=>{
				//         return this.btnexist('stationAudioManage')
				//     },
				// },
			];
			this.operateList = l;
		},
		getlist() {
			let url = "/site/info/queryPage";
			let option = {
				...this.formInline,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			this.$http.post(url, option).then(({ detail }) => {
				this.lists = detail.list;
				this.total = detail.total;
			});
		},
		deleteRecord(brandIdAry) {
			this.$http.post("/baseinfosite/delete", brandIdAry).then(res => {
				this.$message({
					message: res.msg,
					type: "success"
				});
				this.getlist();
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getlist();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		// 导出
		onexport() {
			console.log("导出");
		},
		// 新增
		add() {
			this.$refs.dialog.init();
			console.log("新增");
		},
		// 批量删除
		batchdelete() {
			if (!this.batchdeleteList.length) {
				this.$message({
					type: "warning",
					message: "请先选择删除项"
				});
				return false;
			}
			this.$confirm("是否删除选中站点?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let option = {
						siteIds: this.batchdeleteList.map(item => item.siteId).join(",")
					};
					this.$http.post("/site/info/batchDel", option).then(res => {
						this.$message({
							message: res.msg,
							type: "success"
						});
						this.getlist();
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		},
		// 批量导入
		batchimport() {},

		selectchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},
		selectallchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},

		// 删除
		handleDelete(row) {
			this.$confirm("是否删除该站点?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					this.$http.post("/site/info/del", { siteId: row.siteId }).then(res => {
						this.$message({
							message: res.msg,
							type: "success"
						});
						this.getlist();
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
			//   console.log(row)
		},
		// 修改
		handleEdit(row) {
			this.$refs.dialog.init(row, "update");
			console.log("编辑");
		},
		handleAutoMag(row) {
			this.$refs.audioManagement.init(row);
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getlist();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.getlist();
		}
	}
};
</script>
<style lang="scss" scoped="brand"></style>
<style lang="scss"></style>
