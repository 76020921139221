<template>
    <div class="add_update_station">
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :disabled="isCheck" :rules="rules">
                    <el-form-item label="所属机构:" prop="orgNa" :label-width="formLabelWidth">
                        <div @click="selectInstitutions">
                            <el-input placeholder="请选择" readonly v-model="diaform.orgNa">
                                <el-button slot="append" icon="el-icon-circle-close" @click.stop="clear"></el-button>
                            </el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="站点编号:" prop="siteNu" :label-width="formLabelWidth">
                        <el-input v-model="diaform.siteNu"></el-input>
                    </el-form-item>
                    <el-form-item label="站点名称:" prop="cName" :label-width="formLabelWidth">
                        <el-input v-model="diaform.cName"></el-input>
                    </el-form-item>
                    <el-form-item label="别名:" prop="alias" :label-width="formLabelWidth">
                        <el-input v-model="diaform.alias"></el-input>
                    </el-form-item>
                    <el-form-item label="站点类别:" prop="sType" :label-width="formLabelWidth">
                        <el-input v-model="diaform.sType"></el-input>
                    </el-form-item>
                    <div class="map_select_box">
                        <div>
                            <el-form-item label="经度:" prop="lng" :label-width="formLabelWidth">
                                <el-input v-model="diaform.lng"></el-input>
                            </el-form-item>
                            <el-form-item label="纬度:" prop="lat" :label-width="formLabelWidth">
                                <el-input v-model="diaform.lat"></el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <el-button type="primary" plain v-on:click="selectMap">选择经纬度</el-button>
                        </div>
                    </div>
                    <el-form-item label="所属城市:" prop="bCity" :label-width="formLabelWidth">
                        <el-input v-model="diaform.bCity"></el-input>
                    </el-form-item>
                    <el-form-item label="站点半径:" prop="radius" :label-width="formLabelWidth">
                        <el-input v-model="diaform.radius"></el-input>
                    </el-form-item>
                    <el-form-item label="泊位数:" prop="berths" :label-width="formLabelWidth">
                        <el-input v-model="diaform.berths"></el-input>
                    </el-form-item>
                    <el-form-item label="是否大客流监管站点:" prop="isLargePassSup" :label-width="formLabelWidth">
                        <el-radio-group v-model="diaform.isLargePassSup">
                            <el-radio label="1">是</el-radio>
                            <el-radio label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
            <div v-show="!isCheck" slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
        <map-select ref="mapSelect" @getLngLat="getLngLat"></map-select>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
    </div>
</template>
<script>
import mapSelect from './mapSelect.vue'
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
// let checkId = (rule, value, callback) => {
//         let test = /^[0-9]{8}$/
//         console.log(test.test(value))
//         if (!test.test(value)) {
//             callback(new Error('必须为8位数字'));
//         } else {
//             callback();
//         }
// };
export default {
  data() {
    return {
      // 对话框类型
      type: '',
      title: '',
      isCheck: false,

      diaform: {
        orgId: '',
        orgNa: '',
        siteNu: '',
        cName: '',
        alias: '',
        sType: '',
        lng: '',
        lat: '',
        bCity: '',
        radius: '',
        berths: '',
        isLargePassSup: '',
      },
      dialogVisible: false,
      formLabelWidth: '120px',

      rules: {
        orgNa: [
          {
            required: true,
            message: '请选择所属机构',
            trigger: 'change'
          }
        ],
        siteNu: [
          {
            required: true,
            message: '请输入站点编号',
            trigger: 'blur'
          },
        //   { validator: checkId, trigger: 'blur' },
        ],
        cName: [
          {
            required: true,
            message: '请输入站点名称',
            trigger: 'blur'
          }
        ],
        lng: [
          {
            required: true,
            message: '请输入经度',
            trigger: 'blur'
          }
        ],
        lat: [
          {
            required: true,
            message: '请输入纬度',
            trigger: 'blur'
          }
        ],
        bCity: [
          {
            required: true,
            message: '请输入所属城市',
            trigger: 'blur'
          }
        ]
      },
    }
  },
  components: {
    mapSelect,
    institutionsTree
  },
  watch: {
    dialogVisible(newview) {
      if (!newview) {
        this.resetForm('dialogForm')
        this.diaform.orgId = ''
      }
    }
  },
  created() {
  },
  activated() {
  },
  methods: {
    // 打开对话框
    init(option, type) {
      this.dialogVisible = true
      this.type = type || 'add'
      if (this.type == 'update') {
        this.title = '编辑'
        this.$nextTick(() => {
          this.diaform = {
                      ...this.diaform,
                      ...JSON.parse(JSON.stringify(option))
                    }
        })
      } else {
        this.title = '新增'
      }
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    cancel() {
      this.dialogVisible = false
      console.log('cancel')
    },
    save() {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          let url = ''
          if (this.type == 'add') {
            url = '/site/info/add'
          } else {
            url = '/site/info/update'
          }

          let option = {
            ...this.diaform,
          }
          this.$http.post(url, option).then(res => {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('updateList')
          })
        } else {
          return false
        }
      })
    },

    // 选择机构树
    selectInstitutions() {
        this.$refs.institutions.init()
    },
    // 上级机构查询
    addInstitutions(data) {
        console.log(data)
        this.diaform.orgId = data.orgId
        this.diaform.orgNa = data.orgNa
    },
    clear() {
        this.diaform.orgId = ''
        this.diaform.orgNa = ''
    },

    selectMap() {
        if (this.diaform.lng && this.diaform.lat) {
            let newLngLat = this.$util.wgs84tobd09(Number(this.diaform.lng),Number(this.diaform.lat))
            var option = {
                lng: newLngLat[0],
                lat: newLngLat[1],
            }
            this.$refs.mapSelect.init(option)
        }else{
            this.$refs.mapSelect.init()
        }
    },
    getLngLat(lng, lat) {
        let newLngLat = this.$util.bd09towgs84(lng*1,lat*1)
        this.diaform = {
            ...this.diaform,
            lng:newLngLat[0],
            lat:newLngLat[1],
        }
        this.$nextTick(()=>{
            this.$refs.dialogForm.validateField(['lng','lat'])
        })
    }
  }
}
</script>
<style lang="scss" scoped="add_update_station">
.map_select_box {
  display: flex;
  align-items: center;

  > div {
    &:nth-of-type(1) {
      flex: 1;
    }

    &:nth-of-type(2) {
      margin-left: 20px;
    }
  }
}
</style>
