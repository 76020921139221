<template>
	<div class="audioManagement">
		<jy-dialog type="table" title="报站音频管理" :visible.sync="dialogVisible">
			<div>
				<jy-query ref="checkForm" :model="diaform">
					<jy-query-item label="音频名称:" prop="name" label-width="100px">
						<el-input v-model="diaform.name" placeholder="请输入"></el-input>
					</jy-query-item>
					<template slot="search">
						<el-button type="primary" @click="oncheck">查询</el-button>
						<el-button type="primary" plain @click="resetForm('checkForm')">重置</el-button>
					</template>
					<template slot="operate">
						<el-button type="primary" @click="add">新增音频</el-button>
						<el-button type="primary" @click="batchdelete">批量删除</el-button>
					</template>
				</jy-query>
				<jy-table max-height="595" :data="lists" @select-all="selectallchange" @select="selectchange">
					<jy-table-column type="index" width="60" label="序号" align="center"> </jy-table-column>
					<jy-table-column type="selection" width="40"> </jy-table-column>
					<jy-table-column prop="no" label="音频编号" min-width="100"> </jy-table-column>
					<jy-table-column prop="name" label="音频名称" min-width="100"> </jy-table-column>
					<jy-table-column label="附件" min-width="340">
						<template slot-scope="scope">
							<div>{{ scope.row.attachmentName }}</div>
							<audio class="viewDivNoBorder" controls v-if="isAudio">
								<source :src="scope.row.attachment" type="audio/mpeg" />
								您的浏览器不支持播放
							</audio>
						</template>
					</jy-table-column>
					<jy-operate v-bind:list="operateList" width="200"></jy-operate>
				</jy-table>
				<jy-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageIndex"
					:page-size="pageSize"
					:total="total"
				>
				</jy-pagination>
			</div>
		</jy-dialog>
		<addupdate-audio ref="addupdateAudio" @updateList="getlist"></addupdate-audio>
	</div>
</template>
<script>
import addupdateAudio from "@/components/pages/admin/basicInformation/siteManagement/addupdateAudio";

export default {
	data() {
		return {
			isAudio: true,
			props: {
				children: "children",
				label: "name",
				value: "orgId"
			},
			siteId: "",
			diaform: {
				name: ""
			},
			dialogVisible: false,
			formLabelWidth: "100px",
			lists: [
				{
					routeName: "",
					name: "",
					upMileage: "",
					downMileage: "",
					totalMileage: "",
					task: "",
					remark: "",
					createTime: ""
				}
			],
			// 批量删除
			batchdeleteList: [],
			// 分页
			pageIndex: 1,
			total: 100,
			pageSize: 10,
			//  操作
			operateList: []
		};
	},
	components: {
		addupdateAudio
	},
	created() {
		this.setOperateList();
	},
	activated() {
		// this.getzzAllList()
	},
	watch: {
		dialogVisible(newview) {
			if (!newview) {
				this.resetForm("checkForm");
			}
		}
	},
	methods: {
		init(option) {
			this.dialogVisible = true;
			if (option) {
				this.$nextTick(() => {
					this.siteId = option.siteId;
					this.getlist();
				});
			}
		},
		setOperateList() {
			let l = [
				{
					name: "修改",
					icon: "el-icon-edit-outline",
					fun: this.handleEdit
				},
				{
					name: "删除",
					icon: "el-icon-delete",
					fun: this.handleDelete
				}
			];
			this.operateList = l;
		},
		getlist() {
			let url = "/BaseInfoSiteAudio/list";
			let option = {
				...this.diaform,
				siteId: this.siteId,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			this.$http.post(url, option).then(({ detail }) => {
				this.isAudio = false;
				this.lists = detail.list;
				this.total = detail.total;
				this.$nextTick(() => {
					this.isAudio = true;
				});
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getlist();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		cancel() {
			this.dialogVisible = false;
			console.log("cancel");
		},
		// 新增
		add() {
			this.$refs.addupdateAudio.init({ siteId: this.siteId });
			console.log("新增");
		},
		// 批量删除
		batchdelete() {
			if (this.batchdeleteList.length === 0) {
				this.$message({
					message: "请先选取数据",
					type: "warning"
				});
				return;
			}
			this.$confirm("是否删除选中的音频?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let option = [];
					this.batchdeleteList.forEach(item => {
						option.push(item.id);
					});
					this.deleteRecord(option);
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		},
		// 删除
		handleDelete(row) {
			this.$confirm("是否删除该音频?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let params = [];
					params.push(row.id);
					this.deleteRecord(params);
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		},
		deleteRecord(brandIdAry) {
			this.$http.post("/BaseInfoSiteAudio/delete", brandIdAry).then(res => {
				this.$message({
					message: res.msg,
					type: "success"
				});
				this.getlist();
			});
		},
		selectchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},
		selectallchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},
		// 修改
		handleEdit(row) {
			this.$refs.addupdateAudio.init({ ...row, siteId: this.siteId }, "update");
			console.log("编辑");
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getlist();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.getlist();
		}
	}
};
</script>
<style lang="scss" scoped="audioManagement">
.audioManagement {
	.viewDivNoBorder {
		padding: 3px;
	}
}
</style>
